<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Поправочные коэффициенты</h2>
      <div>
        <UIButton
          class="add-btn"
          text="Добавить"
          leftIcon="bi-plus"
          @click="goToAdd"
        />
      </div>
    </div>
    <TableSlot v-bind="conditionFactors" @rowClick="goToDetails" />
  </div>
</template>

<script>
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button";

import { API_conditionFactor_list } from "@/services/references/conditionFactor";

export default {
  name: "ConditionFactorList",
  components: { TableSlot, UIButton },
  data() {
    return {
      conditionFactors: {
        columns: [
          {
            name: "Шифр",
            path: "WorkIdentifiedObject.IdentifiedObject.enproCode",
          },
          {
            name: "Наименование",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Значение",
            path: "cfValue",
          },
          {
            name: "Тип",
            path: "kind.value", //TODO add ConditionFactorKind
          },
          {
            name: "Статус",
            path: "status.value",
          },
        ],
        data: [],
        filters: {
          "WorkIdentifiedObject.IdentifiedObject.name": "",
          "WorkIdentifiedObject.IdentifiedObject.enproCode": "",
        },
      },
    };
  },
  mounted() {
    API_conditionFactor_list().then(
      (res) => (this.conditionFactors.data = res)
    );
  },
  methods: {
    goToDetails(item) {
      this.$router.push({
        path: `/references/technological-maps/condition-factors/${item.id}`,
      });
    },
    goToAdd() {
      this.$router.push({
        path: `/references/technological-maps/condition-factors/add`,
      });
    },
  },
};
</script>

<style scoped></style>
